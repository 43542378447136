import zlRequest from '@/service'

enum orderAPI {
  dateOrderList = '/food/e/orderList',
  orderAweekData = '/food/e/orderList/aweekCount',
  orderAll = '/user/orderList/all',
  orderCount = '/user/orderCount'
}

import type { reqDataType } from '@/service/types'
import type { OrderDateGoodList, OrderType, OrderCount } from './types'

export async function getOrderListByDate(date: string) {
  const result = await zlRequest.get<reqDataType<OrderDateGoodList>>({
    url: orderAPI.dateOrderList,
    params: {
      date
    }
  })
  return result.data
}

export function getOrderListAweekData() {
  return zlRequest.get<any>({
    url: orderAPI.orderAweekData
  })
}

export function getOrderListByPage(page = 0) {
  return zlRequest.get<reqDataType<OrderType[]>>({
    url: orderAPI.orderAll,
    params: {
      page
    }
  })
}

export function gerAllOrderCount() {
  return zlRequest.get<reqDataType<OrderCount>>({
    url: orderAPI.orderCount
  })
}

// export async function get
