import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent, computed, ref, onUpdated } from 'vue';
export default defineComponent({
  props: {
    tableHeight: {
      type: Number,
      default: 700
    },
    showIndex: {
      type: Boolean,
      default: true
    },
    tableLabel: {
      type: Object,
      required: true
    },
    dataList: {
      type: Array,
      required: true
    },
    Ypagination: {
      type: Boolean,
      default: false
    },
    pagiCurrentChange: {
      type: Function
    },
    funArray: {
      type: Array
    },
    pageCount: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(props) {
    // 找到 传入的配置项，查询是否有 image 属性
    var imageLabel = props.tableLabel ? props.tableLabel.find(function (item) {
      return item.type === 'image';
    }) : {}; // 根据字段插入 imageList

    var imageLabelProp;

    if (imageLabel !== null && imageLabel !== void 0 && imageLabel.prop) {
      imageLabelProp = imageLabel === null || imageLabel === void 0 ? void 0 : imageLabel.prop;
    } // 数据更新时


    onUpdated(function () {
      if (props.dataList.length) {
        imgList.value = [];
        props.dataList.forEach(function (item) {
          if (item[imageLabelProp]) {
            imgList.value.push(item[imageLabelProp]);
          }
        });
      }
    });
    var imgInitialIndex = ref(0);
    var imgList = ref([]);

    var preImg = function preImg(e) {
      var imgsrc = e.target.currentSrc;
      var img_idx = imgList.value.findIndex(function (item) {
        return item === imgsrc;
      });
      if (img_idx !== -1) imgInitialIndex.value = img_idx;
    }; // 搜索模块


    var search = ref('');
    var filterTableData = computed(function () {
      if (!props.dataList) return [];
      var searchResult = props.dataList.filter(function (data) {
        var filed = 'name';
        var serachField = props.tableLabel.find(function (filed) {
          return filed.isSearchFields;
        });

        if (serachField !== null && serachField !== void 0 && serachField.prop) {
          filed = serachField.prop;
        }

        return !search.value || data[filed].toLowerCase().includes(search.value.toLowerCase());
      });
      return searchResult;
    });
    return {
      search: search,
      filterTableData: filterTableData,
      imgList: imgList,
      imgInitialIndex: imgInitialIndex,
      preImg: preImg
    };
  }
});