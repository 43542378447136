import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import { defineComponent, ref, watch } from 'vue';
import yzTable from '@/base-ui/table';
import { orderTableConfig } from '../config/orderTable.config';
import { getOrderListByPage, gerAllOrderCount } from '@/service/main/order/echar';
import yday from '@/utils/day';
export default defineComponent({
  components: {
    yzTable: yzTable
  },
  setup: function setup() {
    var orderListFilter = ref([]);
    var requestOrderList = ref({});
    var pagi = ref(0);
    var pageCount = ref(0);
    gerAllOrderCount().then(function (res) {
      pageCount.value = res.data.count;
    });
    watch(requestOrderList, function (newData) {
      orderListFilter.value = newData[pagi.value];
    }, {
      deep: true
    });
    getOrderListByPage().then(function (res) {
      res.data.forEach(function (item) {
        requestOrderList.value[pagi.value] = res.data;
        item.createAt = yday.formatUtcString(item.createAt);
        item.updateAt = yday.formatUtcString(item.updateAt);
      }); // orderListFilter.value = res.data
    });
    var requestPage = [];
    requestPage.push(0);

    var currentChange = function currentChange(e) {
      var page = e - 1;
      pagi.value = page; // 查询是否已经请求过

      var find = Object.keys(requestOrderList.value).find(function (key) {
        return key === pagi.value + '';
      });

      if (typeof find !== 'undefined') {
        orderListFilter.value = requestOrderList.value[pagi.value];
        return;
      }

      getOrderListByPage(page).then(function (res) {
        res.data.forEach(function (item) {
          // 通过 page 请求成功 把 page 加入 requestPage 里面
          requestOrderList.value[pagi.value] = res.data;
          item.createAt = yday.formatUtcString(item.createAt);
        });
        requestPage.push(page);
      });
    };

    return {
      orderTableConfig: orderTableConfig,
      orderListFilter: orderListFilter,
      pageCount: pageCount,
      currentChange: currentChange
    };
  }
});