import { ITableLabel } from '@/base-ui/table/types'

import { IOrderType } from '../types'

const orderTableConfig: ITableLabel<IOrderType>[] = [
  {
    type: 'normal',
    label: '',
    columnType: 'expand'
  },
  {
    type: 'id',
    label: '排序',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '用户名',
    prop: 'userName',
    isSearchFields: true,
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '订单号',
    prop: 'order_number',
    options: {
      width: '500px',
      align: 'center'
    }
  },
  {
    type: 'normal',
    tag: {
      type: 'success'
    },
    label: '随机码',
    prop: 'random_code',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '创建时间',
    prop: 'createAt',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '支付状态',
    prop: 'status',
    ifPropFields: {
      ifProp: '1',
      value1: {
        text: '已完成',
        tagOptions: {
          type: 'success',
          effect: 'dark',
          size: 'large'
        }
      },
      value2: {
        text: '未完成',
        tagOptions: {
          type: 'warning',
          effect: 'dark',
          size: 'large'
        }
      }
    },
    tag: {
      type: 'success',
      effect: 'dark'
    },
    headerItem: {
      type: 'header',
      placeholder: '商品搜索......',
      options: {
        size: 'small'
      }
    },
    options: {
      align: 'center'
    },
    operationsOption: [
      {
        value: '编辑',
        btnAttribute: {
          size: 'small'
        }
      },
      {
        value: '删除',
        btnAttribute: {
          type: 'danger',
          size: 'small'
        }
      }
    ]
  }
]

export { orderTableConfig }
